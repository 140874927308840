<template>
    <div id="studio-list">
        <el-button type="text" @click="createFormVisible = true">录入直播间</el-button>

        <el-dialog title="请输入直播间信息" :visible.sync="createFormVisible" width="30%">
            <el-form :model="createForm" ref="createCargoForm">
                <el-form-item label="名称">
                    <el-input v-model="createForm.name"></el-input>
                </el-form-item>
                <el-form-item label="房间号">
                    <el-input v-model="createForm.no"></el-input>
                </el-form-item>
                <el-form-item label="类型">
                    <el-select v-model="createForm.studioType" placeholder="请选择">
                        <el-option v-for="studioType in page.studioTypes" :key="studioType" :value="studioType"
                            :label="page.studioTypeDesc[studioType]">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="管理员">
                    <el-select v-model="createForm.managerId" placeholder="请选择">
                        <el-option v-for="au in page.auList" :key="au.id" :value="au.id"
                            :label="'[' + au.id + ']' + au.nickName">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="createFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="
            createOrEdit(null);
        createFormVisible = false;
        ">提交
                </el-button>
            </div>
        </el-dialog>

        <el-divider></el-divider>

        <el-form :model="form" ref="form" inline>
            <!-- <el-form-item label="ID">
                <el-input v-model="form.id"></el-input>
            </el-form-item> -->
            <el-form-item label="名称">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="房间/厅 号">
                <el-input v-model="form.no"></el-input>
            </el-form-item>
            <el-form-item label="类型">
                <el-select v-model="form.studioType" placeholder="请选择" clearable>
                    <el-option v-for="studioType in page.studioTypes" :key="studioType" :value="studioType"
                        :label="page.studioTypeDesc[studioType]">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="loadList">查询</el-button>
            </el-form-item>
        </el-form>

        <el-table :data="page.list" border>
            <el-table-column prop="id" label="ID" width="60">

            </el-table-column>
            <el-table-column label="名称">
                <template v-slot="row">
                    <template v-if="row.row.edit">
                        <el-input v-model="row.row.name"></el-input>
                    </template>
                    <template v-else>
                        {{ row.row.name }}
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="房间/厅 号">
                <template v-slot="row">
                    <template v-if="row.row.edit">
                        <el-input v-model="row.row.no"></el-input>
                    </template>
                    <template v-else>
                        {{ row.row.no }}
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="类型">
                <template v-slot="row">
                    <template v-if="row.row.edit">
                        <el-select v-model="row.row.studioType" placeholder="请选择">
                            <el-option v-for="studioType in page.studioTypes" :key="studioType" :value="studioType"
                                :label="page.studioTypeDesc[studioType]">
                            </el-option>
                        </el-select>
                    </template>
                    <template v-else>
                        {{ page.studioTypeDesc[row.row.studioType] }}

                    </template>
                </template>
            </el-table-column>

            <el-table-column label="管理员">
                <template v-slot="row">
                    <template v-if="row.row.edit">
                        <el-select v-model="row.row.managerId" placeholder="请选择">
                            <el-option v-for="au in page.auList" :key="au.id" :value="au.id"
                                :label="'[' + au.id + ']' + au.nickName">
                            </el-option>
                        </el-select>
                    </template>
                    <template v-else>
                        [{{ row.row.managerId }}]{{ row.row.managerNickName }}
                    </template>



                </template>
            </el-table-column>

            <el-table-column label="创建时间" prop="createTime">
            </el-table-column>


            <el-table-column label="操作" width="400">
                <template v-slot="row">
                    <el-button v-if="!row.row.edit" @click="row.row.edit = true">编辑</el-button>
                    <el-button v-if="row.row.edit" @click="
            createOrEdit(row.row);
        row.row.edit = false;
        ">保存</el-button>
                    <el-button v-if="row.row.edit" @click="row.row.edit = false">取消</el-button>
                    <!-- <el-divider direction="vertical"></el-divider> -->
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="text-align: center" :page-count="page.totalPage" :current-page="form.pageNo"
            layout="prev, pager, next" @current-change="changePage">
        </el-pagination>
    </div>
</template>
<script>
export default {
    name: "AUList",
    data() {
        return {
            form: {
                pageNo: 1,
                name: null,
                no: null,
                studioType: null,
            },

            createFormVisible: false,
            createForm: {
                name: null,
                no: null,
                studioType: null,
                managerId: null,
            },

            editPwdForm: {
                pwd: null,
                nickName: null,
                id: null,
            },

            page: {
                totalPage: 1,
                list: null,

                studioTypes: null,
                studioTypeDesc: null,

                auList: null,

            },
        };
    },

    methods: {
        loadList() {
            this.axios.get("/studio/list", { params: this.form }).then((resp) => {
                if (resp.data.success) {
                    resp.data.data.list.forEach((element) => {
                        element.edit = false;
                    });
                    this.page = resp.data.data;
                } else {
                    this.$message.error(resp.data.msg);
                }
            });
        },

        changePage(val) {
            this.form.pageNo = val;
            this.loadList();
        },

        create() {
            this.axios.post("/studio/create", this.createForm).then((resp) => {
                if (resp.data.success) {
                    this.$message({
                        message: "成功",
                        type: "success",
                    });
                } else {
                    this.$message.error(resp.data.msg);
                }
            });
        },

        createOrEdit(studio) {
            var data = studio == null ? this.createForm : studio;

            this.axios.post("/studio/createOrEdit", data).then((resp) => {
                if (resp.data.success) {
                    this.$message({
                        message: "成功",
                        type: "success",
                    });
                } else {
                    this.$message.error(resp.data.msg);
                }
            });
        },

        getStudioTypeColor(studioType) {
            var map = {
                'ROOT': 'danger',
                'MANAGER': 'warning',
                'TEAM_LEADER': 'success',
                'CS': '',
            }
            var color = map[studioType];
            if (color == null) {
                color = ''
            }

            return color;
        }
    },

    created() {
        this.loadList();
    },
};
</script>

<style scoped></style>